import React from "react";
import DefaultLayout from "../Global Components/DefaultLayout";


const PrivacyPolicy = () => {

    const PageMarkup = (
        <>
            <section className="inner_bnr d-flex align-items-center">
                <div className="container text-center w-100">
                    <h1 className="head_h1 font_bebas blue_text font_reg" data-aos="fade-up" data-aos-duration="1200">Privacy Policy and <span className="black_text">CCPA</span></h1>
                </div>
            </section>

            <section className="term_sec rpivacy-sec left_dots">
                <div className="container">
                    <p className="p_txt black_text bottom_dot"><span className="d-block"><i>Last Revised:8/21/2020</i></span>Nothing in this Policy may be construed to create any obligations for the Company beyond what is required by applicable law.</p>
                    <h4 className=" black_text text-center font_bold info">California residents should access our California Consumer Protection Act (CCPA) Notice at the end of this document.</h4>
                    <ol className="list_txt pt-2">
                        <li>The following Privacy Policy (the “Policy”) describes how <span className="font_bold">[Batch<span className="font_bold blue_text">Service</span>]</span> (“Company”), which owns and operates the website&nbsp;
                            <a href="https://batchservice.net" target="_blank" className="text_bold_blue" rel="noreferrer"> BatchService.net</a>&nbsp;
                            collects, uses, and shares personally identifiable information and non-personally identifiable information through the website and mobile applications it operates (collectively, the “Sites”) and the services, features, or content we offer (collectively with the Sites, the “Services"). This Policy supplements the Terms of Use posted elsewhere within the Sites. You should read the Policy and the Terms of Use before you use the Sites. If you do not understand and agree with this Policy, please do not use the Services.</li>
                        <li>Changes to this Policy. We may change the Policy at any time by posting revisions to the Policy on the Sites. Therefore, you should review the Policy from time to time. If you do not accept all of the terms, conditions and notices set forth in the Policy, you must exit the Sites immediately.</li>
                        <li>Collection of Information from Children. We do not intentionally collect information from or regarding minors.  The Sites are not directed to, or intended for use by, children under the age of 18. Children may not use or submit any information through the Sites or its Services. If you are under the age of 18, you may not use our Sites or Services. If a child under 18 submits information through any part of the Services, and we become aware that the person submitting the information is a child, we will attempt to delete this information as soon as possible.</li>
                        <li className="has-sub-list">Type of Information Collected. We may collect two general types of information when you use the Sites:
                            <ol className="sub_list list_txt" type="a">
                                <li>Personally Identifiable Information (“PII”), which includes contact data we source from third parties and sell to you, your first and last name, home or other physical address, telephone number, email address, user I.D., other identifiers that permit physical or online contact with you, or any information about you collected online and maintained in personally identifiable form in combination with any of the preceding categories. You also may view or engage with our Sites and its Services through third-party social networking Sites or social media plug-ins and applications. When you engage with the Sites and its content through such third-party Sites, plug-ins or applications, we may have access to certain information from your social media profile, such as your name, photo, gender, birthday, location, videos, your list of friends, etc. This information is included in the definition of PII.</li>
                                <li>Non-personally identifiable information (“non-PII”) such as, the type of browser you are using, the type of operating system you are using, the web pages you have visited sites visited before and after you visit the Sites, the type of handheld or mobile device used to access the Sites or other device-specific information.</li>
                            </ol>
                        </li>
                        <li>Certain features available within the Sites will require you to submit PII about yourself as a condition of participation. Some of the features may be offered by us, while others may be offered by third parties. (For example, you may be required to submit PII in order to request information and offers from us and our partners.) When you choose to submit PII to a third party in connection with your use of the Sites, the third party's privacy policy, rather than this Policy, will control the use of your PII. YOU CAN ALWAYS REFUSE TO PROVIDE PII TO US, BUT THIS MAY RESULT IN DECREASED FUNCTIONALITY OF THE SERVICES FOR YOU AND LIMIT YOUR ABILITY TO RECEIVE INFORMATION ABOUT SERVICES THAT MAY BE OF PARTICULAR INTEREST TO YOU.</li>
                        <li>How Information is Collected. We collect information both actively and passively. For example, we will collect information about you that you voluntarily provide while on the Sites. In addition to information provided directly by you, we (and third parties that offer features through the Sites) may collect non-PII through the use of “cookies,” “web beacons,” “flash cookies” or by other electronic means.</li>
                        <li>Cookies. In general, a cookie is a small amount of data sent to your browser from a web server and stored on your computer's hard drive, where it can be used to identify your computer. Cookies can be used to measure web Sites usage, improve navigation around web Sites, and personalize a returning visitor's experience on the web Sites. In most cases, you can set your browser to turn off cookies or to notify you before you receive one so that you can decide whether to accept it or not. Because cookies allow you to take advantage of some of the features on the Sites, we recommend that you leave them turned on. If you block or reject our cookies, some of the features on the Sites may not work for you. You can refer to the instructions for your browser to learn more about these functions.</li>
                        <li>Web Beacons. We, our third-party service providers, advertisers and partners also may use “web beacons” or similar technologies. Web beacons are small strings of code placed on a web page to collect data about how visitors use the Sites. For example, web beacons may be used to count the number of users who visit the Sites or to deliver a cookie to the browser of a visitor to the Sites. If you set your browser to turn off cookies, web beacons and similar technologies will detect your visits to the Sites, but they will not associate your activity on the Sites with information that otherwise would be stored in cookies. We do not control tracking technologies used by third parties on the Sites.</li>
                        <li>Flash Cookies. The Sites uses locally stored objects, also called “Flash cookies,” when it uses Adobe Flash Player (“Flash”) to provide special content such as video streaming, video on demand, video clips or animation. Flash, and similar applications, use Flash cookies to remember visitors' Sites settings, preferences and usages. Flash cookies are managed directly through Adobe's websites, rather than through your web browser.</li>
                        <li>Third-Party Advertising and Third-Party Web Sites. These Sites allows advertising by third parties that provide links to third-party web Sites. Internet advertising companies and the third-party websites on whose behalf they advertise (collectively “Third Parties”) may use the technology described above to send (or “serve”) directly to your browser the advertisements that appear on our Sites. When this happens, Third Parties automatically receive non-PII, such as information from your computer and browser, including your cookie information. They also may use cookies, Javascript, Pixel Tags and other technologies to deliver advertisements; collect information about the effectiveness of their advertisements; collect anonymous information about your visits to the Sites, such as the number of times you have viewed an ad; or customize the advertising content you see. Third Parties will not collect your name, address, or other PII, unless you affirmatively provide it to them. We let Third Parties set and access their cookies on your computer. Third Parties' use of their own cookies is subject to their own privacy policies. For more information about these specialized cookies and other technologies, and to learn more about behavioral advertising or to opt out of this type of advertising for some companies, you can visit http://www.networkadvertising.org or http://networkadvertising.org/optout_nonppii.asp. When using a mobile application, you may receive tailored in-application advertisements. Each operating system provides its own instructions on how to prevent the delivery of tailored in-application advertisements. You may review the instructions and/or the privacy settings for your operating system to find out how to opt out of tailored in-application advertisements.</li>
                        <li>Third Party Links. Please note that this Sites contains links to other web Sites that do not follow this Policy. For instance, clicking on an advertisement, links or other elements on the Sites may take you to an entirely different Sites. Links to other Sites may use our Sites logo or style as a result of a co-branding agreement. These Sites may send their own cookies to you and may collect data and make use of that data in ways that this Sites would not. After you leave our Sites, this Policy will no longer apply to PII or any other data collected from or provided by you. You should check the other web Sites’ applicable privacy policy to determine how it will handle such data.</li>
                        <li className="has-sub-list">How Information is Used and Shared. We use your PII for the purpose of conducting our business and pursuing our legitimate interests. For example, we use your PII to:
                             <ol className="list_txt sub_list less-margin" type="a">
                                <li>Create and manage your account;</li>
                                <li>Provide the products and services you request;</li>
                                <li>Enroll you in contests, programs or other offers you request;</li>
                                <li>Tell you about other products and services that may be of interest to you (you can opt out of receiving such communications by following the instructions provided in the communication);</li>
                                <li>Process payment for purchases you have made;</li>
                                <li>Protect against or identify possible fraudulent transactions;</li>
                                <li>Analyze the use of our Sites;</li>
                                <li>Develop new products and services;</li>
                                <li>Understand how you arrived at the Sites;</li>
                                <li>Manage the Sites and Services;</li>
                                <li>Enforce our Terms of Use; and</li>
                                <li>Enforce the terms of this Policy.</li>
                                
                            </ol>
                            {/* <div className="list_txt" type="a"> */}
                                {/* <p className="p_txt black_text m-0"> Facilitate communication from third parties to you at your request;</p> */}
                                {/* <p className="p_txt black_text m-0"><b>a.</b> Create and manage your account;</p>
                                <p className="p_txt black_text m-0"><b>b.</b> Provide the products and services you request;</p>
                                <p className="p_txt black_text m-0"><b>c.</b> Enroll you in contests, programs or other offers you request;</p>
                                <p className="p_txt black_text m-0"><b>d.</b> Tell you about other products and services that may be of interest to you (you can opt out of receiving such communications by following the instructions provided in the communication);</p>
                                <p className="p_txt black_text m-0"><b>e.</b> Process payment for purchases you have made;</p>
                                <p className="p_txt black_text m-0"><b>f.</b> Protect against or identify possible fraudulent transactions;</p>
                                <p className="p_txt black_text m-0"><b>g.</b> Analyze the use of our Sites;</p>
                                <p className="p_txt black_text m-0"><b>h.</b> Develop new products and services;</p>
                                <p className="p_txt black_text m-0"><b>i.</b> Understand how you arrived at the Sites;</p>
                                <p className="p_txt black_text m-0"><b>j.</b> Manage the Sites and Services;</p>
                                <p className="p_txt black_text m-0"><b>k.</b> Enforce our Terms of Use; and</p>
                                <p className="p_txt black_text"><b>l.</b> Enforce the terms of this Policy.</p>
                            </div> */}
                        </li>
                        <li>
                            {/* Advertisements, Marketing Material and Other Offers.  Unless you tell us that you do not want to receive these offers and subject to applicable law, the Company or those companies that we have a commercial relationship with, may occasionally send advertisements, marketing material or other offers that we think might be of interest to you. If you do not want to receive these advertisements, marketing material or other offers, please contact us using the contact information found at the end of this Policy. Where required by law, we will seek your consent prior to sending you any marketing materials. To the extent allowed by law, the provision of your email and phone number to us constitutes your prior express written consent, and electronic signature, authorizing us to contact you at that email address and phone number, including through the possible use of an automatic telephone dialing system or artificial or prerecorded voice, live calls and text messages, for both promotional and informational reasons. You agree that this consent may be assigned by us to third parties and affiliates. */}
                            You are not required to provide such consent in order to make a purchase as you can always contact us directly to arrange an alternate purchase method.  By providing your telephone number to us, you certify that this is your own number that you own, and not a line owned or used by another, and that you will immediately notify us if your number changes or is reassigned. You agree to indemnify us if this is not the case and if the future owner or user of the number makes a claim against us for contact at that number. You may opt out of such contact at any time and through any of the reasonable methods outlined herein. A copy of our internal, written No-Not-Call Policy will be provided upon request.</li>
                        <li>In the event that Company is considering a sale of its business, in its entirety or a component thereof, or substantially all of its assets are acquired, or a portion thereof, PII and non-PII, may be one of the transferred assets, and may therefore be used by a third-party acquirer in accordance with this Policy. Such use by a third party may include review of PII, using such PII to contact you either before or after such a transfer or sale, use or transfer of non-PII before or after such a transfer or sale, or for other purposes.</li>
                        {/* <li><p className="p_txt black_text">WE MAY DISCLOSE PII ABOUT YOU TO A THIRD PARTY FOR COMPENSATION.</p></li> */}
                        <li className="has_sub_list">In addition, we may share your information as described below:
                            <ol className="list_txt sub_list" type="a">
                                <li>In order to comply with a subpoena, C.I.D. or other valid regulatory or legal request.</li>
                                <li>Information You Elect to Share: You may choose to share information with Third Parties by clicking on links to those Third Parties from within the Sites. In addition, you may elect to share information, including PII, through the Services offered on the Sites by, for instance, posting comments on comment boards. Some of your activity on the Sites, including content you have posted, is public by default. Some of this content may include PII.</li>
                                <li>Third-Party Advertisers and Web Sites: We may share your non-PII with, or make your non-PII available to, Third Parties as described in the section above titled Third-Party Advertising and Third-Party Web Sites.</li>
                                <li>Information Disclosed to Vendors, Partners, Service Providers, and Others for Business Purposes: We contract with vendors to provide services related to the Sites and may share PII with such vendors in order to provide products or services to you or to help maintain the Sites. We also may share non-PII with our partners, service providers and other persons with whom we conduct business. We will only share your PII in accordance with applicable law.</li>
                                <li>Information Disclosed for the Protection of the Sites and for Others. We may disclose PII when (1) required by law or to respond to legal process or lawful requests, including from law enforcement; (2) when we believe it is necessary to prevent, investigate, or address (a) possible illegal activities, including fraud, (b) violation of our Terms of Use, (c) violation of this Policy, or (d) threats to the physical safety of any person; or (4) to protect our rights or property.</li>
                            </ol>
                        </li>
                        <li>Forums. The Sites may include forums (such as message boards, chat rooms, comment boards, and reviews) that enable users to post a comment or communicate with each other. We are under no obligation to moderate or edit the forums and will not be responsible for the content or use of any material posted on any forum within the Sites. We retain the right to delete at any time and for any reason any material posted within the Sites.</li>
                        <li>Information Security. We take information security seriously, and we use reasonable administrative, technical and physical safeguards to protect the PII we collect from unauthorized access, use or disclosure. But we have no control over the security of other websites on the Internet that you might visit. If you share your computer or use a computer that is accessed by the general public, remember to sign off and close your browser window when you have finished your session. This will help to ensure that others cannot access your PII.</li>
                        <li>Additionally, and as you are likely aware, no system can be completely secure. Therefore, although we take commercially reasonable steps to secure your information, we do not promise, and you should not expect, that your PII, searches or other communications always will remain secure. In the event of a breach of the confidentiality or security of your PII, we will notify you if reasonably possible and as reasonably necessary so that you can take appropriate protective steps. We may notify you under such circumstances using the e-mail address(es) we have on record for you. You should also take care with how you handle and disclose your PII. Please refer to the Federal Trade Commission's Web Sites at http://www.consumer.ftc.gov/ for information about how to protect against identity theft.</li>
                        <li>Your Rights. Under the law of some countries, you may have certain rights with respect to your PII. These rights include the right to: (i) request access to and rectification or erasure of your PII; (ii) obtain restriction of processing or object to processing of your PII; and (iii) ask for a copy of your PII to be provided to you or a third party in a machine readable format. If you wish to exercise one of the above-mentioned rights, please send us your request to the contact details set out below. If you have unresolved concerns, you may also have the right to lodge a complaint about the processing of your PII with your local data protection authority in the European Union or elsewhere.</li>
                        <li>California Privacy Rights. Under California law, California residents may request once a year, free of charge, certain information regarding our disclosure of PII to third parties for direct marketing purposes. Specifically, we will provide a list of the categories of PII disclosed to third parties for third-party direct marketing purposes, along with the names and addresses of these third parties. To make such a request, please contact us using the contact information at the bottom of this Policy. We reserve our right not to respond to requests submitted other than to the contact points listed below. You should put “California Privacy Rights” in the subject line and in your request. We may need to verify your identity before providing information to you.</li>
                        <li>California Do Not Track Disclosure. Certain web browsers may allow you to enable a “do not track” option that sends signals to the websites you visit indicating that you do not want your online activities tracked. This is different than blocking cookies as browsers with the “do not track” option selected may still accept cookies. Our Sites currently do not have the functionality to recognize or honor “do not track” browser signaling.  We do not respond to "do not track" signals. If we do so in the future, we will modify this Policy accordingly.</li>
                        <li>California residents should also <b>access our California Consumer Protection Act (CCPA) Notice at the end of this document.</b></li>
                        <li>Users From Outside the United States. This Sites is hosted in the United States and is governed by U.S. law. If you are using the Sites from outside the United States, please be aware that Deseret Digital Media is located in the United States and your PII will be stored and processed in the United States where we locate and operate our servers and databases. The data protection laws in the United States may not be as strict or comprehensive as those in your country. However, we value your privacy and always strive to treat it in accordance with applicable law. By voluntarily sharing your PII with us, you understand that your PII will be stored and processed in the United States.</li>
                        <li>Retention. We retain your PII for as long as you remain a customer and thereafter only for the period necessary to fulfill the purposes outlined in this Policy, unless a longer retention period is required or allowed by law, including to fulfill a legal obligation.</li>
                        <li>Contact Information. Questions concerning the Policy or the Sites, including any request to review or change your PII, should be directed to <a className="blue_text" href="mailto:privacy@batchservice.com"><u>privacy@batchservice.com</u></a>.</li>
                    </ol>
                    <h4 className="p_txt black_text text-center font_bold py-3" style={{ fontSize: "24px" }}>Privacy Notice for Residents of California</h4>
                    <p className="p_txt black_text"><i>Effective: 1/1/2020  &nbsp;&nbsp; Last Revised: 8/21/2020</i></p>
                    <p className="p_txt black_text">This <b>Notice of Privacy is for California Residents</b> and applies to all consumers who interact with <b>Batch<span className="blue_text">Service</span></b> who reside in the State of California. It supplements the information above in our Privacy Policy to comply with the California Consumer Privacy Act of 2018 (CCPA). Terminology defined in the CCPA is defined the same here.  No provision of this Notice may be construed to provide consumers any rights (or our company any obligations) beyond those required by the CCPA and other applicable law.</p>
                    <p className="p_txt black_text"><u>Disabled consumers</u> who need these disclosures presented in an alternate medium, may contact us at the email address or telephone number listed further below.</p>
                    <p className="p_txt black_text"><u>Personal Information That We Collect</u></p>
                    <p className="p_txt black_text m-0">By providing your personal information to us, asking to be contacted or using us to interact with a third party, you are also specifically directing us to disclose your information to any third-parties listed, along with others who we feel may have information or offers you may be interested in.</p>
                    <p className="p_txt black_text">We collect personal information directly from you and by observing your interactions on our website which identifies, relates to, describes and/or is capable of being associated with or could reasonably be linked, directly or indirectly, with a particular consumer, household or device. In the last twelve months we have collected the categories of information listed below. We will not collect additional categories of personal information or use the personal information we collected for materially different, unrelated, or incompatible purposes without providing you notice. The following is an overview of information defined by the CCPA as personal information.</p>
                    <div className="table-responsive custom_tbl">
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <td><b>A.</b> Unique personal identifiers</td>
                                    <td>A real name, account name, address, alias, bank account number, credit card number, email address, debit card number, driver's license number, insurance policy number, internet Protocol address, passport number, physical characteristics or description, postal address, online identifier, signature, Social Security number, state identification card number, telephone number, unique personal identifier, or other similar identifiers.</td>
                                </tr>
                                <tr>
                                    <td><b>B.</b> Characteristics of protected classNameifications under California or federal law</td>
                                    <td>Age (40 years or older), ancestry, citizenship, color, disability (physical or mental), marital status, medical condition, national origin, race, religion or creed, sex (including gender, gender identity, gender expression, pregnancy or childbirth and related medical conditions), sexual orientation, veteran or military status, genetic information (including familial genetic information).</td>
                                </tr>
                                <tr>
                                    <td><b>C.</b> Commercial information</td>
                                    <td>Products or services considered, obtained or purchased, purchasing or consuming histories or tendencies, records of personal property.</td>
                                </tr>
                                <tr>
                                    <td><b>D.</b> Biometric information</td>
                                    <td>Activity patterns used to extract a template, behavioral, biological, genetic or physiological characteristics, other identifiers or identifying information eg:, face, finger or voiceprints, gait tendencies, health information like exercise or sleep information, iris or retina scans, keystrokes or other physical patterns.</td>
                                </tr>
                                <tr>
                                    <td><b>E.</b> Internet or other electronic network activity information</td>
                                    <td>Browsing history, search history, information regarding interaction with advertisements, applications or websites.</td>
                                </tr>
                                <tr>
                                    <td><b>F.</b> Geolocation information</td>
                                    <td>Physical location or movements including trends.</td>
                                </tr>
                                <tr>
                                    <td><b>G.</b> Sensory information</td>
                                    <td>Audio, electronic, visual, thermal, olfactory or similar data.</td>
                                </tr>
                                <tr>
                                    <td><b>H.</b> Professional or employment related information</td>
                                    <td>Past or present job history, performance evaluations.</td>
                                </tr>
                                <tr>
                                    <td><b>I.</b> Non-public / Private (non-public) educational information protected by the Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).</td>
                                    <td>className lists, disciplinary records, financial information, grades, identification codes, schedules, transcripts or other information directly related to a student which is collected by educational institutions or agencies acting on their behalf.</td>
                                </tr>
                                <tr>
                                    <td><b>J.</b> Inferences or conclusions drawn from personal information</td>
                                    <td>Profiles reflecting an individual’s abilities, attitudes, aptitudes, behavior, characteristics, intelligence, predispositions, preferences, psychological trends etc.</td>
                                </tr>
                                <tr>
                                    <td><b>K.</b> Personal Information not governed by the CCPA</td>
                                    <td>De-identified or aggregated consumer information, information excluded from the CCPA’s scope (eg: health or medical information covered by the Health Insurance Portability and Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or clinical trial data). Personal information covered by certain sector-specific privacy laws, including the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act (FIPA), and the Driver’s Privacy Protection Act of 1994, publicly available information from government records.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <h4 className="p_txt black_text text-center font_bold py-2">We obtain personal information from the following categories of sources:</h4>
                    <div className="table-responsive custom_tbl">
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <td width="50%"><b>A.</b> Client or their agents, directly or indirectly</td>
                                    <td width="50%"><b>B.</b> Government entities from which public records are obtained</td>
                                </tr>
                                <tr>
                                    <td width="50%"><b>C.</b> Third parties, advertising networks, consumer data resellers, data analytics providers, government entities, internet service providers, social networks, operating systems and platforms</td>
                                    <td width="50%"><b>D.</b> Website interactions from our contact us and other web forms.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <h4 className="p_txt black_text text-center font_bold py-2">How We Use Personal Information regulated by the CCPA</h4>
                    <div className="table-responsive custom_tbl">
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <td><b>A.</b> As described upon collection of information</td>
                                    <td>As set forth in the CCPA.</td>
                                </tr>
                                <tr>
                                    <td><b>B.</b> Complying with the law</td>
                                    <td>Obeying the court, law and government regulations, responding to requests from law enforcement and other governing bodies.</td>
                                </tr>
                                <tr>
                                    <td><b>C.</b> Fulfill requests, the reason you provided the information</td>
                                    <td>Answering questions, facilitating delivery, fulfilling purchases, providing information on products or services, price quotes, processing payments and transactions, processing product orders, processing returns.</td>
                                </tr>
                                <tr>
                                    <td><b>D.</b> Maintaining safety and security</td>
                                    <td>Preventing theft of information, products and services as well as technological resources and preventing transactional fraud.</td>
                                </tr>
                                <tr>
                                    <td><b>E.</b> Personalizing the customer experience</td>
                                    <td>Personalizing your experience to deliver content, product and service offerings relevant to your interests (eg; targeting offers and ads through our website, third-party sites and via email or text messaging –with your consent, where required by law).</td>
                                </tr>
                                <tr>
                                    <td><b>F.</b> Product development</td>
                                    <td>Product, service and website improvement, research and analysis.</td>
                                </tr>
                                <tr>
                                    <td><b>G.</b> Providing support</td>
                                    <td>Developing and personalizing our products, services and website, investigating and addressing concerns, monitoring and improving responses.</td>
                                </tr>
                                <tr>
                                    <td><b>H.</b> Restructuring</td>
                                    <td>Evaluating or conducing mergers, divestures, reorganization, sale or transfer or assets in the event of bankruptcy, liquidation, etc.</td>
                                </tr>
                                <tr>
                                    <td><b>I.</b> For our own Client and affiliate/partner’s use.</td>
                                    <td>To provide to our own clients, affiliates/partners, as allowed by law, to the extent we believe they may have offers or other information of use to the consumer.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <h4 className="p_txt black_text font_bold mb_10">Sharing Personal Information</h4>
                    <p className="p_txt black_text mb_10">We may disclose your personal information to a third party (eg: Service providers and data aggregators) for a business purpose. When we disclose your information for business purposes our contracts state the purpose and require all parties to use personal information for the sole purpose of executing the contract and also that all personal information be kept confidential.</p>
                    <h4 className="p_txt black_text font_bold mb_10 title-div">Personal Information Disclosed in the Last 12 Months</h4>
                    <p className="p_txt black_text">In the last twelve months, we have disclosed the categories of personal information below for a business purpose.</p>
                    <div className="table-responsive custom_tbl">
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <td width="50%"><b>A.</b> Biometric information? No</td>
                                    <td width="50%"><b>B.</b> Characteristics of protected classNameifications under California or federal law? Yes</td>
                                </tr>
                                <tr>
                                    <td width="50%"><b>C.</b> Commercial information? Yes</td>
                                    <td width="50%"><b>D.</b> Geolocation information? Yes</td>
                                </tr>
                                <tr>
                                    <td width="50%"><b>E.</b> Inferences or conclusions drawn from personal information? Yes</td>
                                    <td width="50%"><b>F.</b> Internet or other electronic network activity information? Yes</td>
                                </tr>
                                <tr>
                                    <td width="50%"><b>G.</b> Non-public / Private (non-public) educational information protected by the Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99))? Yes</td>
                                    <td width="50%"><b>H.</b> Professional or employment related information? Yes</td>
                                </tr>
                                <tr>
                                    <td width="50%"><b>I.</b> Sensory information? Yes</td>
                                    <td width="50%"><b>J.</b> Unique personal identifiers? Yes</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <h4 className="p_txt black_text font_bold mb_10 title-div">Personal Information Sold in the Last 12 Months</h4>
                    <p className="p_txt black_text">In the last twelve months we have sold the categories below of personal information for a business purpose. Note that under the CCPA, <i>a business does not “sell” personal information when a consumer uses or directs the business to intentionally disclose personal information or uses the business to intentionally interact with a third party, provided the third party does not also sell the personal information, unless that disclosure would be consistent with the provisions of this title. An intentional interaction occurs when the consumer intends to interact with the third party, via one or more deliberate interactions.</i></p>
                    <div className="table-responsive custom_tbl">
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <td width="50%"><b>A.</b> Unique personal identifiers? Yes</td>
                                    <td width="50%"><b>B.</b> Characteristics of protected classNameifications under California or federal law? Yes</td>
                                </tr>
                                <tr>
                                    <td width="50%"><b>C.</b> Commercial information? Yes</td>
                                    <td width="50%"><b>D.</b> Biometric information? No</td>
                                </tr>
                                <tr>
                                    <td width="50%"><b>E.</b> Internet or other electronic network activity information? Yes</td>
                                    <td width="50%"><b>F.</b> Geolocation information? Yes</td>
                                </tr>
                                <tr>
                                    <td width="50%"><b>G.</b> Sensory information? Yes</td>
                                    <td width="50%"><b>H.</b> Professional or employment-related information? Yes</td>
                                </tr>
                                <tr>
                                    <td width="50%"><b>I.</b> Non-public / Private (non-public) educational information protected by the Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99))? Yes</td>
                                    <td width="50%"><b>J.</b> Inferences or conclusions are drawn from personal information? Yes</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <h4 className="p_txt black_text font_bold mb_10 title-div">Your Rights and Choices</h4>
                    <p className="p_txt black_text m-0">The CCPA provides consumers with specific rights regarding their personal information if they are California residents. The following is an overview of these rights and an explanation of how to exercise them.</p>
                    <p className="p_txt black_text"><span className="font_bold">1. Specific Information Access and Data Portability Rights</span> California residents have the right to request the disclose of what CCPA personal information we have collected about them in the last twelve months and what we have used it for during this time period. To the extent required by the CCPA, when we receive a verifiable consumer request, we will disclose the following information, after verifying your identity, if we can:</p>
                    <div className="table-responsive custom_tbl">
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <td width="50%"><b>A.</b> Business or commercial purposes for collecting or selling personal information.</td>
                                    <td width="50%"><b>B.</b> Categories of personal information collected about you.</td>
                                </tr>
                                <tr>
                                    <td width="50%"><b>C.</b> Categories of sources we use to collect personal information about you.</td>
                                    <td width="50%"><b>D.</b> Categories of third parties we share your personal information with.</td>
                                </tr>
                                <tr>
                                    <td width="50%"><b>E.</b> Internet or other electronic network activity information collected or maintained.</td>
                                    <td width="50%"><b>F.</b> Personal information categories that each information recipient category disclosed to each recipient.</td>
                                </tr>
                                <tr>
                                    <td width="50%"><b>G.</b> Personal information categories that each information recipient category sold to each recipient.</td>
                                    <td width="50%"><b>H.</b> Specific pieces of personal information we collected about you, so that you can make a data request. We will provide you with this information in a portable format.</td>
                                </tr>
                                <tr>
                                    <td width="50%"><b>I.</b> Whether we disclosed or sold your personal information for a business purpose, two separate lists.</td>
                                    <td width="50%"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p className="p_txt black_text"><span className="font_bold">2. Deletion Request Rights</span> California residents have the right to request that we delete any of the CCPA personal information which we collected about them. After we have received and confirmed your verifiable consumer request (see Exercising Access, Data Portability, and Deletion Rights), we will delete your personal information and instruct our service providers to delete your information as well. We may not delete your information if it is required to us or our service providers to do the following:</p>
                    <div className="table-responsive custom_tbl">
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <td width="50%"><b>A.</b> Completing the contract or transaction for which we collected the personal information, providing goods or services requested, taking reasonable actions within the scope of our ongoing business relationship with you.</td>
                                    <td width="50%"><b>B.</b> Complying with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 et. seq.), and other legal obligations.</td>
                                </tr>
                                <tr>
                                    <td width="50%"><b>C.</b> Complying with free speech rights, engaging in free speech, ensuring consumers right to exercise their free speech.</td>
                                    <td width="50%"><b>D.</b> Complying with other laws.</td>
                                </tr>
                                <tr>
                                    <td width="50%"><b>E.</b> Finding and resolving defects or errors impacting intended product, service or website functionality.</td>
                                    <td width="50%"><b>F.</b> Internal processes in alignment with your relationship with us.</td>
                                </tr>
                                <tr>
                                    <td width="50%"><b>G.</b> Identifying security risks, protecting against deceptive, fraudulent, malicious, or illegal activity, or prosecuting individuals involved in such activities.</td>
                                    <td width="50%"><b>H.</b> Participating in public or peer-reviewed historical, scientific, or statistical research which is in the public interest and abides by ethics standards, if the information is required to complete the research and you have provided informed consent.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p className="p_txt black_text"><span className="font_bold">3. Exercising Access, Data Portability, and Deletion Rights</span> Only you or someone registered with the California Secretary of State who is authorized to act in your interests may make a request for information or deletion. Once you have made a verifiable consumer request, we will provide your personal information is a portable manner so that you can access it easily. Any information you provide will only be used to verify your identity. You can make a request following the instructions below:</p>
                    <div className="table-responsive custom_tbl">
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <td width="50%"><b>A.</b> Use our online <b>Request to Know</b> form, call us or email us using the contact information found at the end of this notice. We will confirm receipt within 10 days and then provide a written response within 45 days. If we require more time (up to 90 days), we will inform you in writing of the reason.</td>
                                    <td width="50%"><b>B.</b> Provide us with information necessary to verify your identity so that we may release the information to the individual to whom it belongs, or an authorized representative.</td>
                                </tr>
                                <tr>
                                    <td width="50%"><b>C.</b> You need not create an account to make requests, but if you have an account and login with us, signing in through our portal with a password serves to verify your identity.</td>
                                    <td width="50%"><b>D.</b> Provide enough detail in your request that we understand you, can evaluate the request and provide an answer.</td>
                                </tr>
                                <tr>
                                    <td width="50%"><b>E.</b> Select the format in which you would like to receive your personal information. This format should be readily accessible and allow you to transport the data to others without issue.</td>
                                    <td width="50%"><b>F.</b> We will provide access to your personal information for the last twelve-month period or provide an explanation as to who we cannot comply with the request.</td>
                                </tr>
                                <tr>
                                    <td width="50%"><b>G.</b> There is no fee to process your verifiable consumer request unless it is excessive. In with case we will inform you of the fee before proceeding.</td>
                                    <td width="50%"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p className="p_txt black_text">You will need to prove your identity to us. If you have no password-protected account with us, you will need to provide us with at least 2 data points which we already maintain about you, and possibly other information and a signed declaration under penalty of perjury if the information maintained about you is of a sensitive nature. In some cases, we might not be able to successfully verify your information and in such a case, we will notify you as to why we cannot provide it.</p>
                    <p className="p_txt black_text"><span className="font_bold">4. Personal Information Sales Opt-Out and Opt-In Rights</span> California residents who are sixteen years of age or older have the right to dictate that we do not sell their personal information. If you opt-in to information sales, either verbally or in writing, you may opt-out at any time. Minors are not allowed to use our websites or services and we do not intentionally collect any personal information for or regarding those under the age of eighteen. To opt out please contact us using the contact information found at the end of this notice.</p>
                    <p className="p_txt black_text"><span className="font_bold">5. Financial Incentives & Non-Discrimination Rights</span> To the extent required by the CCPA, we will not discriminate against individuals who choose to exercise any of their rights under the CCPA. Unless allowed by the CCPA, we will not:</p>
                    <div className="table-responsive custom_tbl">
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <td width="50%"><b>A.</b> Deny you good or services because you exercised your rights.</td>
                                    <td width="50%"><b>B.</b> Charge different rates or prices, impost penalties or grant differential discounts for punitive purposes.</td>
                                </tr>
                                <tr>
                                    <td width="50%"><b>C.</b> Provide (or suggest) those who exercise their rights different quality goods or a different level of service.</td>
                                    <td width="50%"><b>D.</b> We may, however, offer financial incentives to those who provide personal information which may result in variations in price, rates or quality depending on the value of the information provided.</td>
                                </tr>
                                <tr>
                                    <td width="50%"><b>E.</b> You must opt-in or provide prior written consent to participate in financial incentive programs and you may revoke this consent at any time.</td>
                                    <td width="50%"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p className="p_txt black_text">We do not offer financial incentives to consumers to induce their agreement for us to sell their personal information. Rather, if you do not agree, we might not be allowed to provide your information to third parties who have the quotes, services or other information you have requested. Note that under the CCPA, the “sale” of information does not include the disclosure of information at the consumer’s request.</p>
                    <p className="p_txt black_text mb_10"><b>6.</b> <u>Other California Privacy Rights</u></p>
                    <p className="p_txt black_text">California’s “Shine the Light” law (Civil Code Section § 1798.83) provides California residents with the right to request information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make a request, please contact us using the contact information found at the end of this notice.</p>
                    <p className="p_txt black_text mb_10"><u>Changes to Our Privacy Notice</u></p>
                    <p className="p_txt black_text">We reserve the right to amend this notice of privacy at any time and at our sole discretion. When changes are made, we will post a notice on the main website and the date the changes take effect. Continued use of this website indicates your acceptance of these changes.</p>
                    <p className="p_txt black_text mb_10"><b>Contact Information</b></p>
                    <p className="p_txt black_text">If you have any questions, comments or queries about our privacy policy, how we collect or use personal information, your rights regarding such use, or how to exercise your rights please feel free to contact us at <a href="mailto:privacy@batchservice.com" className="blue_text">privacy@batchservice.com</a> or calling  <a href="tel: (888) 351-4693" className="blue_text"> (888) 351-4693</a>.</p>

                    <p className="p_txt black_text mb-2"><b>Privacy Policy Verbiage</b></p>
                    <p className="p_txt black_text">This site is being monitored by one or more third-party monitoring software(s), and may capture information about your visit that will help us improve the quality of our service. You may opt-out from the data that SmartPiXL is collecting on your visit through a universal consumer options page located at https://smart-pixl.com/Unsub/unsub.html.</p>
                </div>
                {/*<div className="text-center aos-init aos-animate mt-3" data-aos="fade-up" data-aos-duration="1200">*/}
                {/*    <a href="/ccpa" type="button" className="btn blue_btn">Click here for CCPA form </a>*/}
                {/*</div>*/}



            </section>


        </>
    );
    return (
        <>

            <DefaultLayout View={PageMarkup} />
        </>
    )
}

export default PrivacyPolicy;