import React, { useState } from "react";
import DefaultLayout from "../Global Components/DefaultLayout";
import UserIcon from "../../Assets/Images/user.svg";
import EmailIcon from "../../Assets/Images/email.svg";
import PhoneIcon from "../../Assets/Images/phone.svg";

import CommentIcon from "../../Assets/Images/pi-chat.svg";
import ContactImg from "../../Assets/Images/contact.svg";
import ZapierForm from 'react-zapier-form'


const Contact = () => {
    const [Firstname, SetFirstname] = useState("");
    const [Lastname, SetLastname] = useState("");
    const [Email, SetEmail] = useState("");
    const [Comments, SetComments] = useState("");


    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }


    const PageMarkup = (
        <>
            <section className="inner_bnr d-flex align-items-center">
                <div className="container text-center w-100">
                    <h1 className="head_h1 font_bebas blue_text font_reg" data-aos="fade-up" data-aos-duration="1200">
                        Contact <span className="black_text">Us</span>
                    </h1>
                </div>
            </section>
            <section className="form_sec inner_dots pt-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 text-center" data-aos="fade-right" data-aos-duration="1200">
                            <h3 className="black_text font_bold text-center blue_text">GET IN TOUCH <span style={{ color: "#24356C" }}> WITH US </span></h3>
                            <img src={ContactImg} className="contactus" alt="contat" />
                        </div>
                        <div className="col-md-6 leftDot_line" data-aos="fade-left" data-aos-duration="1200">
                            <div className="form_box">
                                <ZapierForm action='https://hooks.zapier.com/hooks/catch/8982132/b31yt3y/'>
                                    {({ error, loading, success }) => {
                                        return (
                                            <div>
                                                {!success && !loading &&
                                                    <div>

                                                        <div className="form-group">
                                                            <input type="text" value={Firstname} onChange={(e) => SetFirstname(e.target.value)} className="form-control" placeholder="First Name*" name="fname" id="fname" required />
                                                            <div className="form_icon d-flex align-items-center justify-content-center"><img src={UserIcon} alt="user" /></div>
                                                            <span id="error_name"></span>
                                                        </div>
                                                        <div className="form-group">
                                                            <input type="text" value={Lastname} onChange={(e) => SetLastname(e.target.value)} className="form-control" placeholder="Last Name*" name="lname" id="lname" required />
                                                            <div className="form_icon d-flex align-items-center justify-content-center"><img src={UserIcon} alt="user" /></div>
                                                            <span id="error_lastname"></span>
                                                        </div>
                                                        <div className="form-group">
                                                            <input type="email" value={Email} onChange={(e) => SetEmail(e.target.value)} className="form-control" placeholder="Email*" name="email" id="email" required />
                                                            <div className="form_icon d-flex align-items-center justify-content-center"><img src={EmailIcon} alt="email" /></div>
                                                            <span id="error_email"></span>
                                                        </div>

                                                        <div className="form-group">
                                                            <input type="number" maxLength="11" onInput={maxLengthCheck} className="form-control" placeholder="Phone" id="phone" name="number" />
                                                            <div className="form_icon d-flex align-items-center justify-content-center"><img src={PhoneIcon} alt="phone" /></div>
                                                            <span id="error_phone"></span>
                                                        </div>


                                                        <div className="form-group">
                                                            <textarea className="form-control" onChange={(e) => SetComments(e.target.value)} value={Comments} rows="3" name="comment" placeholder="Message*" required ></textarea>
                                                            <div className="form_icon comment_icon d-flex align-items-center justify-content-center"><img className="comment_icon" src={CommentIcon} alt="Message" /></div>
                                                        </div>
                                                        <div className="text-center">
                                                            <button type="submit" className="btn blue_btn con-btn">SEND</button>
                                                        </div></div>
                                                }
                                                {loading && <div>Loading...</div>}
                                                {error && <div className="error_msg text-center"><p className="text_black font_bold text_18 text-center">Something went wrong. Please try again later.</p></div>}
                                                {success && <div className="error_msg text-center"><h1 className="head_h2 font_24 font_bold ">Thank you! </h1><p className="text-center text_black font_bold text_18">We will be in touch with you shortly.</p></div>}
                                            </div>
                                        )
                                    }}
                                </ZapierForm>



                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="location d-flex justify-content-center mt-5">
                <div className="container">
                    <div className="maps text-center">
                        <h4 className="blue_text font_bold">US Office</h4>
                        <p><a href="https://www.google.com/maps/place/2800+N+24th+St,+Phoenix,+AZ+85016,+USA/@33.4783947,-112.0323953,17.25z/data=!4m5!3m4!1s0x872b0d93fccb74fb:0x4d3dd50ac85c9494!8m2!3d33.4783625!4d-112.0304507" target="_blank" rel="noreferrer" >
                            2800 N 24th St Phoenix,<br />
                            Arizona 85008, USA
                        </a></p>
                    </div>

                    <div className="maps text-center">
                        <h4 className="blue_text font_bold">India Office</h4>
                        <p><a href="https://www.google.com/maps/search/Infopark+Thrissur,+Koratty+P.O.+Kerala-680308,+India/@10.2678021,76.354268,18z/data=!3m1!4b1" target="_blank" rel="noreferrer" >
                            Infopark Thrissur, Koratty P.O.<br />
                            Kerala-680308, India
                        </a> </p>
                    </div>
                </div>
            </section>


        </>
    );
    return (
        <>

            <DefaultLayout View={PageMarkup} />
        </>
    )
}

export default Contact;