import React from 'react';
import DefaultLayout from "../Global Components/DefaultLayout";
import FrameImg from '../../Assets/Images/Frame.svg';
import BLLOGO from '../../Assets/Images/BLlogo.svg';
import BSTLOGO from '../../Assets/Images/BatchSkipTracing.svg';
import BDLOGO from '../../Assets/Images/BatchDialer-B-A-C 2.svg';
import BDRLOGO from '../../Assets/Images/BatchDriven.svg';
import BALOGO from '../../Assets/Images/batchdata.svg';


const Homepage = () => {
    const PageMarkup = (

        <>
            <section className="main-top">
                <div className="container">
                    <div className="row   justify-content-center align-items-center">
                        <div className="main_heading col-md-6 aos-init" data-aos="fade-left" data-aos-duration="1200">
                            <h1>ALL THE TOOLS YOU NEED<br></br>
                                <span className="text_bold_blue">FOR THE BEST</span><br></br>
                                SALES PERFORMANCE</h1>
                        </div>
                        <div className="main_heading col-md-6 aos-init" data-aos="fade-right" data-aos-duration="1200">
                            <img src={FrameImg} alt="Tool" />
                        </div>
                    </div>
                    <div className="strips_bg">
                        <p className="font_20 my-0">
                            <span className="text_bold_black">Batch</span><span className="text_bold_blue">Service</span> is a collaborative company dedicated to providing cutting-edge SaaS (Software as a Service) platforms to the real estate and outbound sales industries.
                        </p> </div>


                    <div className="intro_text aos-init" data-aos="fade-left" data-aos-duration="1200">
                        <p>
                            We pride ourselves on being
                            <span className="orange_text"> one of the best and most innovative </span>companies nationwide. We are team driven and our unique culture
                            gives us a competitive edge which drives profitability and stability. It's a culture that maximizes the career development of
                            all of our team members, regardless of their job title. We are an
                            invested group of people who are all-in to grow this incredible
                            opportunity and have a high degree of professionalism while also
                            having fun.
                        </p>
                    </div>
                </div></section>

            <section className="logo_service mt-5 aos-init" data-aos="fade-down" data-aos-duration="1200">
                <div className="container">
                    <div className="row">
                        <div className="bl"  >
                            <a href="https://batchleads.io" target="_blank" rel="noreferrer"><img src={BLLOGO} alt="bl" /></a>
                        </div>
                        <div className="bl bstt">
                            <a href="https://batchskiptracing.com" target="_blank" rel="noreferrer"> <img src={BSTLOGO} alt="bdr" /></a>
                        </div>
                        <div className="bl">
                            <a href="https://batchdialer.com" target="_blank" rel="noreferrer"><img src={BDLOGO} alt="bd" /></a>
                        </div>
                        <div className="bl">
                            <a href="https://batchdriven.com" target="_blank" rel="noreferrer"> <img src={BDRLOGO} alt="bdr" /></a>
                        </div>
                        <div className="bl api_logo">
                            <a href="https://batchdata.com" target="_blank" rel="noreferrer">   <img src={BALOGO} alt="ba" /></a>
                        </div>
                    </div>
                </div>
            </section>


        </>

    );
    return (
        <>

            <DefaultLayout View={PageMarkup} />

        </>
    )

}



export default Homepage;

