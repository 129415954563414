import React, { useState } from "react";
import DefaultLayout from "../Global Components/DefaultLayout";
import UserIcon from "../../Assets/Images/user.svg";
import EmailIcon from "../../Assets/Images/email.svg";
import PhoneIcon from "../../Assets/Images/phone.svg";


import BLLOGO from '../../Assets/Images/BLlogo.svg';
import BSTLOGO from '../../Assets/Images/BatchSkipTracing.svg';
import BDLOGO from '../../Assets/Images/BatchDialer-B-A-C 2.svg';
import BDRLOGO from '../../Assets/Images/BatchDriven.svg';
import BALOGO from '../../Assets/Images/batchdata.svg';
import CommentIcon from "../../Assets/Images/pi-chat.svg";
import { Helmet } from "react-helmet";
import ZapierForm from 'react-zapier-form'


const Removal = () => {
    const [Firstname, SetFirstname] = useState("");
    const [Lastname, SetLastname] = useState("");
    const [Email, SetEmail] = useState("");
    const [Comments, SetComments] = useState("");


    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }


    const PageMarkup = (
        <>
            <section className="inner_bnr d-flex align-items-center">
                <div className="container text-center w-100">
                    <h1 className="head_h1 font_bebas blue_text font_reg" data-aos="fade-up" data-aos-duration="1200">
                        REMOVAL <span className="black_text"> REQUEST</span>
                    </h1>
                </div>
            </section>
            <section className="form_sec inner_dots pt-5 removal-req">
                <div className="container">
                    <div className="row align-items-justify">
                        <div className="col-md-6 text_black text-justify pt-5" data-aos="fade-right" data-aos-duration="1200">
                            <p>Your personal privacy is our highest priority and we are happy accommodate your request to remove your personal information from our services.</p>

                            <p>We may need to reach out to you directly via email to verify your request and assure that it will be processed safely and accurately. Please check your email for correspondence over the next few days.</p>                        </div>
                        <div className="col-md-6 leftDot_line" data-aos="fade-left" data-aos-duration="1200">
                            <div className="form_box">
                                <ZapierForm action='https://hooks.zapier.com/hooks/catch/2424086/onmtyix/'>
                                    {({ error, loading, success }) => {
                                        return (
                                            <div>
                                                {!success && !loading &&
                                                    <div>

                                                        <div className="form-group">
                                                            <input type="text" value={Firstname} onChange={(e) => SetFirstname(e.target.value)} className="form-control" placeholder="First Name*" name="fname" id="fname" required />
                                                            <div className="form_icon d-flex align-items-center justify-content-center"><img src={UserIcon} alt="user" /></div>
                                                            <span id="error_name"></span>
                                                        </div>
                                                        <div className="form-group">
                                                            <input type="text" value={Lastname} onChange={(e) => SetLastname(e.target.value)} className="form-control" placeholder="Last Name*" name="lname" id="lname" required />
                                                            <div className="form_icon d-flex align-items-center justify-content-center"><img src={UserIcon} alt="user" /></div>
                                                            <span id="error_lastname"></span>
                                                        </div>
                                                        <div className="form-group">
                                                            <input type="email" value={Email} onChange={(e) => SetEmail(e.target.value)} className="form-control" placeholder="Email*" name="email" id="email" required />
                                                            <div className="form_icon d-flex align-items-center justify-content-center"><img src={EmailIcon} alt="email" /></div>
                                                            <span id="error_email"></span>
                                                        </div>

                                                        <div className="form-group">
                                                            <input type="number" maxLength="11" onInput={maxLengthCheck} className="form-control" placeholder="Phone" id="phone" name="number" />
                                                            <div className="form_icon d-flex align-items-center justify-content-center"><img src={PhoneIcon} alt="phone" /></div>
                                                            <span id="error_phone"></span>
                                                        </div>


                                                        <div className="form-group">
                                                            <textarea className="form-control" onChange={(e) => SetComments(e.target.value)} value={Comments} rows="3" name="comment" placeholder="Message*" required ></textarea>
                                                            <div className="form_icon comment_icon d-flex align-items-center justify-content-center"><img src={CommentIcon} alt="Message" /></div>
                                                        </div>
                                                        <div className="text-center">
                                                            <button type="submit" className="btn white_btn con-btn">REMOVE MY DATA</button>
                                                        </div></div>
                                                }
                                                {loading && <div>Loading...</div>}
                                                {error && <div className="error_msg text-center"><p className="text_black font_bold text_18 text-center">Something went wrong. Please try again later.</p></div>}
                                                {success && <div className="error_msg text-center"><h1 className="head_h2 font_24 font_bold ">Thank you! </h1><p className="text-center text_black font_bold text_18">We will be in touch with you shortly.</p></div>}
                                            </div>
                                        )
                                    }}
                                </ZapierForm>



                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="location d-flex justify-content-center mt-5">
                <div className="container">
                    <div className="tour_vid text-center">
                        <video height="auto" width="600px" poster={HeroImage} autoPlay loop>
                            <source src={HeroVid} type="video/mp4" />
                            <source src={HeroVid} type="video/webm" />
                        </video>
                        <div className="mobile-v">
                            <iframe width="673" height="250" src="https://www.youtube.com/embed/iIMVVYhZje4?rel=0?&controls=0&autoplay=1&showinfo=0&loop=1&modestbranding=1&autohide=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div> </div>
                </div>
            </section> */}
            <section className="service_intro text-center">
                <div className="container">
                    <h4 className="text_bold_blue text_upr">We have 5 platforms and they ALL stand out in their respective industry:</h4>
                </div>
            </section>
            <section className="logo_service serve_img mt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <a href="https://batchdriven.com" target="_blank" rel="noreferrer"> <img src={BDRLOGO} alt="bdr" /></a>
                        </div>
                        <div className="col-md-4">
                            <a href="https://batchleads.io" target="_blank" rel="noreferrer"><img src={BLLOGO} alt="bl" /></a>
                        </div>
                        <div className="col-md-4">
                            <a href="https://batchservice.io" target="_blank" rel="noreferrer"> <img src={BALOGO} alt="ba" /></a>
                        </div></div>
                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className=" col-md-4">
                            <a href="https://batchskiptracing.com" target="_blank" rel="noreferrer"> <img src={BSTLOGO} alt="bdr" /></a>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-4">
                            <a href="https://batchdialer.com" target="_blank" rel="noreferrer"><img src={BDLOGO} alt="bd" /></a>
                        </div>
                        <div className="col-md-1"></div>


                    </div>
                </div>
            </section>


        </>
    );
    return (
        <>
            <Helmet>
                <title>Removal | Batchservice</title>

            </Helmet>
            <DefaultLayout View={PageMarkup} />
        </>
    )
}

export default Removal;