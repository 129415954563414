import React, { useState, useEffect } from 'react'
import { Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Logo from "../../Assets/Images/logo.svg";
import Footer from "../Global Components/Footer";
import { Modal } from 'react-bootstrap';
import UserIcon from "../../Assets/Images/name.svg";
import EmailIcon from "../../Assets/Images/mail.svg";
import UploadIcon from "../../Assets/Images/bx_bx-cloud-upload.svg";
import PhoneIcon from "../../Assets/Images/phn.svg";
import FormImg from "../../Assets/Images/welcm.svg";
import ZapierForm from 'react-zapier-form'



const DefaultLayout = (props) => {
    const [ShowFixedHeader, SetShowFixedHeader] = useState(false);
    const [ShowContactModal, SetContactModal] = useState(false);

    const ScrollHandle = () => {
        let ScrollPos = window.scrollY;
        if (ScrollPos >= 80) {
            SetShowFixedHeader(true);
        }
        else {
            SetShowFixedHeader(false);
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', ScrollHandle);

        return () => {
            window.removeEventListener('scroll', ScrollHandle);
        };
    }, []);

    const MenuScrollTop = (className) => {
        if (document.getElementsByClassName(className) !== null) {
            let MenuFeat = document.getElementsByClassName(className).offsetTop;
            window.scrollTo({
                top: MenuFeat - 0,
                behavior: 'smooth',
            })
        }

    }

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    return (
        <>
            <header className={ShowFixedHeader ? "header darkHeader" : "header"}>
                <Navbar bg="light" expand="lg">
                    <div className="container">
                        <Navbar.Brand as={Link} to="/" className="logobar" onClick={() => MenuScrollTop('logobar')}><img src={Logo} alt="logo"></img></Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="ml-auto d-flex align-items-center">
                                <Nav.Link as={Link} to="/" className={`nav-link ${window.location.pathname
                                    === "/" ? 'active' : ''}`} >Home</Nav.Link>
                                <Nav.Link as={Link} className={`nav-link ${window.location.pathname
                                    === "/products" ? 'active' : ''}`} to="/products"> Products</Nav.Link>
                                <Nav.Link as={Link} className={`nav-link ${window.location.pathname
                                    === "/contact-us" ? 'active' : ''}`} to="/contact-us">Contact Us</Nav.Link>
                                {/*<Nav.Link as={Link} className="blue_btn" to="/careers">Careers</Nav.Link>*/}

                                {/* <Nav.Link as={Link} to="#" className="blue_btn" onClick={() => SetContactModal(true)}  >  Apply</Nav.Link> */}

                            </Nav>

                        </Navbar.Collapse>
                    </div>
                </Navbar>
            </header>


            <main className="main_content pt_78">
                {props.View}
            </main>
            <Footer></Footer>

            <Modal show={ShowContactModal} centered="true" className="custom_bodal" backdrop="static" keyboard={false} id="contactus">
                <Modal.Body className="contact_body">
                    <button type="button" className="close position-absolute" onClick={() => SetContactModal(false)}>
                        <span aria-hidden="true">×</span>
                    </button>

                    <div className="form_box ">
                        <h2 className="head_h2 black_text font_bebas font_reg"><span className="text_black_bold">APPLY TO </span> <span className="text_bold_blue">WORK FOR US!</span></h2>
                        <p className="font_20">Please complete the form below to apply for a position with us.</p>
                        <div className="row">

                            <div className="form col-md-4">

                                <ZapierForm action='https://hooks.zapier.com/hooks/catch/2424086/ojmzl5y/'>
                                    {({ error, loading, success }) => {
                                        return (
                                            <div>
                                                {!success && !loading &&

                                                    <div>
                                                        <div className="form-group">
                                                            <input type="text" className="form-control" placeholder="First Name*" name="fname" id="fname" required />
                                                            <div className="form_icon d-flex align-items-center justify-content-center"><img src={UserIcon} alt="user" /></div>
                                                            <span id="error_name"></span>
                                                        </div>
                                                        <div className="form-group">
                                                            <input type="text" className="form-control" placeholder="Last Name*" name="lname" id="lname" required />
                                                            <div className="form_icon d-flex align-items-center justify-content-center"><img src={UserIcon} alt="user" /></div>
                                                            <span id="error_lastname"></span>
                                                        </div>
                                                        <div className="form-group">
                                                            <input type="email" className="form-control" placeholder="Email*" name="email" id="email" required />
                                                            <div className="form_icon d-flex align-items-center justify-content-center"><img src={EmailIcon} alt="email" /></div>
                                                            <span id="error_email"></span>
                                                        </div>
                                                        <div className="form-group">
                                                            <input type="number" className="form-control" maxLength="11" onInput={maxLengthCheck} placeholder="Phone Number*" name="phnumber" id="telephone" required />
                                                            <div className="form_icon d-flex align-items-center justify-content-center"><img src={PhoneIcon} alt="phone" /></div>
                                                            <span id="error_tel"></span>
                                                        </div>
                                                        <div className="form-group">
                                                            <input type="file" id="myFile" name="filename" />
                                                            <div className="form_icon d-flex align-items-center justify-content-center"><img src={UploadIcon} alt="upload" />

                                                            </div>
                                                            <span id="error_file"></span>
                                                        </div>
                                                        <div className="text-center">
                                                            <button type="submit" className="btn blue_btn w-100">Apply</button>
                                                        </div>
                                                    </div>

                                                }
                                                {loading && <div>Loading...</div>}
                                                {error && <div className="thankyou"><p>Something went wrong. Please try again later.</p></div>}
                                                {success && <div className="thankyou"><h2 className="text_bold_blue text-center">Thank you!</h2>

                                                    <p className="text_black_bold">We will be in touch
with you shortly. for contacting us!</p></div>}
                                            </div>
                                        )
                                    }}
                                </ZapierForm>

                            </div>

                            <div className="formimg col-md-8">
                                <img className="formimg" src={FormImg} alt="form" />

                            </div>


                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default DefaultLayout;
