import React from 'react';
import DefaultLayout from './DefaultLayout';
import ErrorImage from '../../Assets/Images/error404.svg';

const ErrorPage = (props) => {
    const PageMarkup = (
        <>
            {props.Errortype === "Error404" ?
                <img src={ErrorImage} className="error-img" alt="error" />
                : ""}
        </>
    );

    return <DefaultLayout View={PageMarkup} />
}

export default ErrorPage;
