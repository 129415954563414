import React from 'react';
import DefaultLayout from "../Global Components/DefaultLayout";
import BLImg from '../../Assets/Images/BL.svg';
import BlLogo from '../../Assets/Images/BLlogo.svg';
import BorderImg from '../../Assets/Images/Line.svg';
import BSTLogo from '../../Assets/Images/BatchSkipTracing.svg';
import BST from '../../Assets/Images/BST.svg';
import BD from '../../Assets/Images/BDL.svg';
import APIImg from '../../Assets/Images/API.svg';
import BDRImg from '../../Assets/Images/BDRr.svg';
import BDRLogo from '../../Assets/Images/BatchDriven.svg';
import BADATALOGO from '../../Assets/Images/batchdata.svg';

import BDLogo from '../../Assets/Images/BatchDialer-B-A-C 2.svg';









const Homepage = () => {
    const PageMarkup = (

        <>

            <section className="services pt-5" id="batchleads">
                <div className="container custom_container">
                    <h2 className="text-center">We Have <span className="text_bold_blue">5 Platforms</span> & <span className="text_bold_blue">They ALL Stand Out</span> in Their Respective Industry:</h2>

                    <div className="batchleads col-md-12 px-0 pt-5">
                        <div className="row justify-content-center align-items-top">
                            <div className="sec_img col-md-6 aos-init" data-aos="fade-right" data-aos-duration="1200">
                                <img src={BLImg} alt="batchleads-logo" />
                            </div>
                            <div className="col-md-1"></div>
                            <div className="logo_sec col-md-5 aos-init" data-aos="fade-right" data-aos-duration="1200">
                                <img src={BlLogo} alt="logo" />
                                <p className="font_black ">The ultimate all-in-one real estate investor prospecting platform. The platform enables real estate professionals to find more leads, close more deals and maximize revenue!  BatchLeads helps real estate professionals organize and manage lists, find motivated sellers and stop wasting money targeting the wrong people.</p>
                                <a className="blue_btn" href="https://batchleads.io">VISIT WEBSITE</a>

                                <div className="borderImg"><img src={BorderImg} alt="border"></img></div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <section className="services" id="batchskiptracing">
                <div className="container custom_container">
                    <div className="row justify-content-center align-items-center">
                        <div className="logo_sec col-md-5 aos-init" data-aos="fade-left" data-aos-duration="1200">
                            <img src={BSTLogo} alt="logo" />
                            <p className="font_black ">The industry’s best skip tracing tool. BatchSkipTracing provides real estate investors and debtors the contact information they need to get a hold of the right people the first time.</p>
                            <a className="blue_btn" href="https://batchskiptracing.com">VISIT WEBSITE</a>
                            <div className="borderImg"><img src={BorderImg} alt="border"></img></div>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="sec_img col-md-6 aos-init" data-aos="fade-left" data-aos-duration="1200">
                            <img src={BST} alt="bST-logo" />
                        </div>

                    </div></div></section>
            <section className="services" id="batchdialer">
                <div className="container custom_container">
                    <div className="row justify-content-center align-items-center" >
                        <div className="sec_img col-md-6 aos-init" data-aos="fade-right" data-aos-duration="1200">

                            <img src={BD} alt="bd" />
                        </div>
                        <div className="col-md-1"></div>
                        <div className="logo_sec col-md-5 aos-init" data-aos="fade-right" data-aos-duration="1200">
                            <img src={BDLogo} alt="logo" />
                            <p className="font_black ">Our fully-featured dialing platform allows anyone in the sales industry to talk to more people in less time. Predictive dialing, manual dialing and inbound dialing for the sales and cold calling industry.</p>
                            <a className="blue_btn" href="https://batchdialer.com">VISIT WEBSITE</a>
                            <div className="borderImg"><img src={BorderImg} alt="border"></img></div>

                        </div>



                    </div></div></section>

            <section className="services" id="batchdriven">
                <div className="container custom_container">
                    <div className="row justify-content-center align-items-center" >
                        <div className="logo_sec col-md-5 aos-init" data-aos="fade-left" data-aos-duration="1200">
                            <img src={BDRLogo} alt="logo" />
                            <p className="font_black ">BatchDriven helps real estate investors and realtors knock doors and simultaneously drive for dollars for the hottest off-market properties with little-to-no competition. It will help you reduce marketing costs, boost margins and improve productivity and efficiency.</p>
                            <a className="blue_btn" href="https://batchdriven.com">VISIT WEBSITE</a>
                            <div className="borderImg"><img src={BorderImg} alt="border"></img></div>

                        </div>
                        <div className="col-md-1"></div>
                        <div className="sec_img col-md-6 aos-init" data-aos="fade-left" data-aos-duration="1200">
                            <img src={BDRImg} alt="bdr" />
                        </div>
                    </div>
                </div>
            </section>


            <section className="services" id="batchapi">
                <div className="container custom_container">
                    <div className="row justify-content-center align-items-center" >
                        <div className="sec_img col-md-6 aos-init" data-aos="fade-right" data-aos-duration="1200">

                            <img src={APIImg} alt="bd" />
                        </div>
                        <div className="logo_sec p_50 col-md-6 aos-init" data-aos="fade-right" data-aos-duration="1200">

                            <img src={BADATALOGO} alt="batchdata" />
                            <p className="font_black ">BatchData is a secure, enterprise-grade cloud data platform that provides businesses with a wide range of data products and services. We help businesses clean, verify and enrich their data through our state-of-the-art on-demand online platform, APIs and Batch Processing.</p>
                            <a className="blue_btn" href="https://batchdata.com">VISIT WEBSITE</a>
                            <div className="borderImg"><img src={BorderImg} alt="border"></img></div>
                        </div>
                    </div>
                </div></section>

        </>

    );
    return (
        <>

            <DefaultLayout View={PageMarkup} />

        </>
    )

}



export default Homepage;

