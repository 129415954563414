import React, { useEffect } from "react";
import { BrowserRouter } from 'react-router-dom';
import InternalRouter from "./Components/Snippets/InternalRouter";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import '../src/Assets/Css/Responsive.css';
import Homepage from "./Components/Route Components/Homepage";
import AOS from 'aos';
import "aos/dist/aos.css";


function App() {

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <BrowserRouter>
        <InternalRouter>
          <Homepage></Homepage>
        </InternalRouter>
      </BrowserRouter>
    </>
  );
}

export default App;
