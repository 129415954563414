import React from 'react'
import DefaultLayout from '../Global Components/DefaultLayout';
import { useEffect } from 'react';
import HeroVid from '../../Assets/Images/Batch-office.mp4';
import HeroImage from '../../Assets/Images/heroimg.png';
import BLLOGO from '../../Assets/Images/BLlogo.svg';
import BSTLOGO from '../../Assets/Images/BatchSkipTracing.svg';
import BDLOGO from '../../Assets/Images/BatchDialer-B-A-C 2.svg';
import BDRLOGO from '../../Assets/Images/BatchDriven.svg';
import BALOGO from '../../Assets/Images/batchdata.svg';


const Domestic = () => {

    useEffect(() => {
        const script = document.createElement('script');

        script.src = "https://recruitingbypaycor.com/career/iframe.action?clientId=8a7883d0782317770178235ffa4a0088";
        script.async = true;
        script.id = 'gnewtonjs';
        script.type = 'text/javascript';
        document.getElementById("international").appendChild(script);
        // document.body.appendChild(script);


        return () => {
            // document.getElementById.removeChild(script);

        }
    }, []);

    const PageMarkup = (

        <>

            <section className="inner_bnr DomesticHires  d-flex align-items-center">
                <div className="container text-center w-100">
                    <h1 className="head_h1 font_bebas blue_text font_reg" data-aos="fade-down" data-aos-duration="1200">
                        International  <span className="black_text"> JOBS</span>
                    </h1>
                </div>

            </section>

            <section className="iframe_div">

                <div className="container">
                    <div id="international">
                    </div>
                </div>
            </section>
            <section className="location text-center d-flex justify-content-center ">
                <div className="container">
                    <h4>Reside in US? Check our United States jobs:</h4>
                    <div className="btn domesticbtn mt-2 d-block align-items-center text-center">

                        <a className="text-center blue_btn" href="/united-states-jobs">UNITED STATES JOBS</a>
                    </div>
                    <div className="tour_vid mt-5 text-center">
                        <video height="auto" width="600px" poster={HeroImage} autoPlay loop>
                            <source src={HeroVid} type="video/mp4" />
                            <source src={HeroVid} type="video/webm" />
                        </video>
                        {/* <div className="mobile-v">
                            <iframe width="673" height="250" src="https://www.youtube.com/embed/iIMVVYhZje4?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=0&autoplay=1&playlist=iIMVVYhZje4&loop=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>  */}
                    </div>
                </div>
            </section>
            <section className="service_intro text-center">
                <div className="container">
                    <h4 className="text_bold_blue text_upr">We have 5 platforms and they ALL stand out in their respective industry:</h4>
                </div>
            </section>
            <section className="logo_service serve_img mt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <a href="https://batchdriven.com" target="_blank" rel="noreferrer"> <img src={BDRLOGO} alt="bdr" /></a>
                        </div>
                        <div className="col-md-4">
                            <a href="https://batchleads.io" target="_blank" rel="noreferrer"><img src={BLLOGO} alt="bl" /></a>
                        </div>
                        <div className="col-md-4 api_logo">
                            <a href="https://batchdata.io" target="_blank" rel="noreferrer">  <img src={BALOGO} alt="ba" class="batchdata" /></a>
                        </div></div>
                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-4">
                            <a href="https://batchskiptracing.com" target="_blank" rel="noreferrer"> <img src={BSTLOGO} alt="bdr" /></a>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-4">
                            <a href="https://batchdialer.com" target="_blank" rel="noreferrer"><img src={BDLOGO} alt="bd" /></a>
                        </div>
                        <div className="col-md-1"></div>


                    </div>
                </div>
            </section>


        </>

    );
    return (
        <>
            <DefaultLayout View={PageMarkup} />

        </>

    )

}
export default Domestic;