import React from 'react'
import { Switch, Route } from "react-router-dom";
import ErrorPage from '../Global Components/ErrorPage';
import Homepage from '../Route Components/Homepage';
import Products from '../Route Components/Products';
import Contact from '../Route Components/Contact';
import RemovalRequest from '../Route Components/RemovalRequest';
import Career from '../Route Components/Career';
import Domestic from '../Route Components/Domestic';
import International from '../Route Components/International';
import PrivacyPolicy from '../Route Components/PrivacyPolicy';
import Ccpa from '../Route Components/Ccpa';

function Internalrouter() {
    return (
        <>
            <Switch>
                <Route exact
                    path="/"
                    render={(props) => <Homepage {...props} />}
                ></Route>

                <Route exact
                    path="/products"
                    render={(props) => <Products {...props} />}
                ></Route>


                <Route exact
                    path="/careers"
                    render={(props) => <Career {...props} />}
                ></Route>
                <Route exact
                    path="/united-states-jobs"
                    render={(props) => <Domestic {...props} />}
                ></Route>
                <Route exact
                    path="/international-jobs"
                    render={(props) => <International {...props} />}
                ></Route>
                <Route exact
                    path="/contact-us"
                    render={(props) => <Contact {...props} />}
                ></Route>
                <Route exact
                    path="/privacy-policy"
                    render={(props) => <PrivacyPolicy {...props} />}
                ></Route>
                <Route exact
                    path="/Ccpa"
                    render={(props) => <Ccpa {...props} />}
                ></Route>



                <Route exact
                    path="/personal-information"
                    render={(props) => <RemovalRequest {...props} />}
                ></Route>

                {/******** If the page doesn;t exist, show a 404 page **********/}
                <Route render={() => <ErrorPage Errortype="Error404" />} />
                {/******** If the page doesn;t exist, show a 404 page **********/}
            </Switch>
        </>
    );
}

export default Internalrouter;