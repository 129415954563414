import React from "react";
import ZapierForm from 'react-zapier-form'
import DefaultLayout from "../Global Components/DefaultLayout";
import DotsSvg from "../../Assets/Images/re-dots.svg"

const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
        object.target.value = object.target.value.slice(0, object.target.maxLength)
    }
}


const Ccpa = () => {


    const PageMarkup = (
        <>
            <section className="inner_bnr d-flex align-items-center">
                <div className="container text-center w-100">
                    <h1 className="head_h1 font_bebas blue_text font_reg" data-aos="fade-up" data-aos-duration="1200">CONATCT INFORMATION
                        <span className="black_text d-block">REMOVAL REQUEST (CCPA)</span></h1>
                </div>
            </section>
            <section className="form_sec removal inner_dots">
                <div className="container">
                    <div className="row align-items-justify">
                        <div className="col-md-5 text_16 pp-p" data-aos="fade-right" data-aos-duration="1200">
                            <p className="black_text mb-0">To the extent required by the California Consumer Protection Act (CCPA): California residents who want to know what Personal Information (as defined in the CCPA) we maintain about them may send a Request to Know using the webform below or by calling us at <strong> (888) 351-4693 </strong> during business hours. We will confirm receipt of your Request within 10 days and fulfill your request within 45 days, or up to 90 days if we notify you as to why we need more time. You will first be required to prove your identity as required by the CCPA and as set forth in our Privacy Notice to California Residents.</p>
                            <p className="black_text mt-5">If we cannot successfully verify your identity, or if the information would be too sensitive to disclose, we may not be able to provide the Personal Information to you. You may only submit 2 requests per year.</p>


                            <img src={DotsSvg} alt="dots" />
                        </div>

                        <div className="col-md-6 leftDot_line full-display" data-aos="fade-left" data-aos-duration="1200">
                            <div className="form_box col-md-12 pt-0">
                                <ZapierForm action='https://hooks.zapier.com/hooks/catch/2424086/onm6ps4/'>
                                    {({ error, loading, success }) => {
                                        return (
                                            <div>
                                                {!success && !loading &&
                                                    <div>
                                                        <h3>Request Information Removal оf:</h3>

                                                        <div className="form-group">
                                                            <input type="text" className="form-control" placeholder="First Name*" id="fname" name="fname" required />
                                                            <div className="form_icon d-flex align-items-center justify-content-center"><img src="https://batchleads.io/Assets/Images/user.svg" alt="user" /></div>
                                                            <span id="error_name"></span>
                                                        </div>
                                                        <div className="form-group">
                                                            <input type="text" className="form-control" placeholder="Last Name*" name="lname" id="lname" required />
                                                            <div className="form_icon d-flex align-items-center justify-content-center"><img src="https://batchleads.io/Assets/Images/user.svg" alt="user" /></div>
                                                            <span id="error_lastname"></span>
                                                        </div>
                                                        <div className="form-group ">
                                                            <input type="number" maxLength="11" onInput={maxLengthCheck} className="form-control" placeholder="Phone*" name="phone" id="phone" required />
                                                            <div className="form_icon d-flex align-items-center justify-content-center"><img src="https://batchleads.io/Assets/Images/phone.svg" alt="phone" /></div>
                                                        </div>
                                                        <div className="form-group">
                                                            <input type="email" className="form-control" placeholder="Email*" name="email" id="email" required />
                                                            <div className="form_icon d-flex align-items-center justify-content-center"><img src="https://batchleads.io/Assets/Images/email.svg" alt="email" /></div>
                                                            <span id="error_email"></span>
                                                        </div>
                                                        <div className="form-group">
                                                            <textarea className="form-control" rows="3" name="comment" placeholder="Comment"></textarea>
                                                            <div className="form_icon comment_icon d-flex align-items-center justify-content-center"><img src="https://batchleads.io/Assets/Images/pi-chat.svg" alt="comment" /></div>
                                                        </div>
                                                        <div className="form-group">
                                                            <div className="file-upload">
                                                                <div className="file-select">
                                                                    <i className="fa fa-upload" aria-hidden="true"></i>

                                                                    <input type="file" name="chooseFile" id="chooseFile" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h3>Your Contact Information:</h3>
                                                        <div className="form-group">
                                                            <input type="text" className="form-control" placeholder="First Name*" id="Fname" name="Fname" required />
                                                            <div className="form_icon d-flex align-items-center justify-content-center"><img src="https://batchleads.io/Assets/Images/user.svg" alt="user" /></div>
                                                            <span id="error_name"></span>
                                                        </div>
                                                        <div className="form-group">
                                                            <input type="text" className="form-control" placeholder="Last Name*" name="Lname" id="Lname" required />
                                                            <div className="form_icon d-flex align-items-center justify-content-center"><img src="https://batchleads.io/Assets/Images/user.svg" alt="user" /></div>
                                                            <span id="error_lastname"></span>
                                                        </div>
                                                        <div className="form-group ">
                                                            <input type="number" maxLength="11" onInput={maxLengthCheck} className="form-control" placeholder="Phone*" name="Phone" id="Phone" required />
                                                            <div className="form_icon d-flex align-items-center justify-content-center"><img src="https://batchleads.io/Assets/Images/phone.svg" alt="phone" /></div>
                                                        </div>
                                                        <div className="form-group">
                                                            <input type="email" className="form-control" placeholder="Email*" name="Email" id="Email" required />
                                                            <div className="form_icon d-flex align-items-center justify-content-center"><img src="https://batchleads.io/Assets/Images/email.svg" alt="email" /></div>
                                                            <span id="error_email"></span>
                                                        </div>

                                                        <div className="text-center">
                                                            <button className="btn blue_btn">REMOVE MY DATA</button>

                                                        </div>
                                                    </div>
                                                }
                                                {loading && <div>Loading...</div>}
                                                {error && <div className="error_msg text-center"><p className="text_black font_bold text_18 text-center">Something went wrong. Please try again later.</p></div>}
                                                {success && <div className="error_msg text-center"><h1 className="head_h2 font_24 font_bold ">Thank you! </h1><p className="text-center text_black font_bold text_18">We will be in touch with you shortly.</p></div>}
                                            </div>
                                        )
                                    }}
                                </ZapierForm>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
    return (
        <>
            <DefaultLayout View={PageMarkup} />
        </>
    )
}

export default Ccpa;