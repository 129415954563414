import React from 'react'
import FBicon from '../../Assets/Images/entypo-social_facebook-with-circle.svg';
import Instaicon from '../../Assets/Images/bx_bxl-instagram-alt.svg';
import YTicon from '../../Assets/Images/ph_youtube-logo-fill.svg';
import LDicon from '../../Assets/Images/akar-icons_linkedin-fill.svg';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <>

            <footer className="footer_sec mt-5">
                <div className="container text-center">


                    <div className="social_icon pb-3">
                        <a href="https://www.facebook.com/batchskiptracing" title=""
                            target="_blank" rel="noreferrer" ><img src={FBicon} alt="fb" /></a>
                        <a className="instai" href="https://www.instagram.com/batchskiptracing/" title=""
                            target="_blank" rel="noreferrer" ><img src={Instaicon} alt="insta"
                            /></a>
                        <a className="instai" href="https://www.youtube.com/c/BatchTV" title=""
                            rel="noreferrer" target="_blank"><img src={YTicon} alt="Youtube"
                            /></a>
                        <a href="https://www.linkedin.com/company/batchservices" title=""
                            rel="noreferrer" target="_blank"><img src={LDicon} alt="Linkdin"
                            /></a>
                    </div>

                    {/* <div className="footer_menu">
                        <a href="terms.html" title="">Terms of Service</a>
                        <a href="privacy-policy.html" title="">Private Policy</a>
                        <a href="personal-information.html" title="">Personal Information</a>
                    </div> */}
                </div>
                <div className="footer">
                    <div className="container text-center">
                        <p>@BatchService 2021. All rights reserved.</p>
                        <div className="footer_menu">
                            <Nav.Link as={Link} to="/privacy-policy">Privacy Policy</Nav.Link>
                        </div>
                    </div>
                </div>
            </footer>




        </>

    )
}

export default Footer;
