import React from 'react'
import HeroVid from '../../Assets/Images/Batch-office.mp4';
import HeroImage from '../../Assets/Images/heroimg.png';
import WorkUs from '../../Assets/Images/workus.png';
import BLLOGO from '../../Assets/Images/BLlogo.svg';
import BSTLOGO from '../../Assets/Images/BatchSkipTracing.svg';
import BDLOGO from '../../Assets/Images/BatchDialer-B-A-C 2.svg';
import BDRLOGO from '../../Assets/Images/BatchDriven.svg';
import BALOGO from '../../Assets/Images/batchdata.svg';
import Target from '../../Assets/Images/001-target.svg';
import Core from '../../Assets/Images/002-focus.svg';
import DefaultLayout from '../Global Components/DefaultLayout';

const Careers = () => {


    const PageMarkup = (

        <>

            <section className="inner_bnr careers  d-flex align-items-center">
                <div className="container text-center w-100">
                    <h1 className="head_h1 font_bebas blue_text font_reg" data-aos="fade-down" data-aos-duration="1200">
                        WORK <span className="black_text">WITH US</span>
                    </h1>
                </div>

            </section>

            <section className="career_info mt-5 text-center">

                <div className="container">

                    <h4 className="text_blue">BatchService is a leading SaaS (Software as a Service) company. We are passionate about technology and pride ourselves on being one of the best and most innovative companies nationwide.</h4>


                    <div className="row">
                        <div className="col-md-7 pr-3">
                            <div className="text_black text-justify pt-3" data-aos="fade-right" data-aos-duration="1200">
                                <p>We are team-driven and our unique culture gives us a competitive edge that drives profitability and stability. It's a culture that maximizes the career development of all of our team members, regardless of their job title.  </p>

                                <p>We are an invested group of people who are all-in to grow this incredible opportunity and have a high degree of professionalism while also having fun. </p>
                            </div>
                            <div className="vision">
                                <div className="col-md-9" data-aos="fade-right" data-aos-duration="1200">
                                    <div className="media align-items-center serve_media">
                                        <div className="media-left d-flex align-items-center justify-content-center"><img src={Target} alt="serve" /></div>
                                        <div className="media-body text-left">
                                            <h5 className="head_h3 blue_text font_med">Our Vision</h5>
                                            <p className="m-0 black_text">We design, build and provide products and services that impact lives. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3"></div>
                                    <div className="col-md-9 less_top" data-aos="fade-right" data-aos-duration="1200">
                                        <div className="media align-items-center serve_media">
                                            <div className="media-left d-flex align-items-center justify-content-center"><img src={Core} alt="serve" /></div>
                                            <div className="media-body text-left">
                                                <h5 className="head_h3 blue_text font_med">Our Core Values</h5>
                                                <p className="m-0 black_text">Customer obsessed, determined to have ultimate success, curious and innovative, taking extreme ownership, leading with integrity.</p>
                                            </div>
                                        </div>
                                    </div></div>
                            </div>
                        </div>
                        <div className="col-md-5 workimg aos-init" data-aos="fade-left" data-aos-duration="1200">
                            <img src={WorkUs} alt="work" />
                        </div>
                    </div>  </div>

            </section>
            <section className="location d-flex justify-content-center mt-5">
                <div className="container">
                    <div className="tour_vid text-center">
                        <div className="btn domesticbtn  d-block align-items-center text-center">
                            <a className="text-center blue_btn" href="/united-states-jobs">UNITED STATES JOBS</a>
                            <a className="text-center world blue_btn" href="/international-jobs">INTERNATIONAL JOBS</a>
                        </div>
                        <video height="auto" width="600px" poster={HeroImage} autoPlay loop>
                            <source src={HeroVid} type="video/mp4" />
                            <source src={HeroVid} type="video/webm" />
                        </video>
                        {/* <div className="mobile-v">
                            <iframe id="iframvid" width="673" height="250" src="https://www.youtube.com/embed/iIMVVYhZje4?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=0&autoplay=1&loop=1&mute=1&playlist=iIMVVYhZje4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div> */}
                    </div>
                </div>
            </section>
            <section className="service_intro text-center">
                <div className="container">
                    <h4 className="text_bold_blue text_upr">We have 5 platforms and they ALL stand out in their respective industry:</h4>
                </div>
            </section>
            <section className="logo_service serve_img mt-5 aos-init" data-aos="fade-down" data-aos-duration="1200">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <a href="https://batchdriven.com" target="_blank" rel="noreferrer"> <img src={BDRLOGO} alt="bdr" /></a>
                        </div>
                        <div className="col-md-4">
                            <a href="https://batchleads.io" target="_blank" rel="noreferrer" ><img src={BLLOGO} alt="bl" /></a>
                        </div>
                        <div className="col-md-4 api_logo">
                            <a href="https://batchdata.io/" ><img src={BALOGO} alt="ba" class="batchdata" /></a>
                        </div></div>
                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className=" col-md-4">
                            <a href="https://batchskiptracing.com" target="_blank" rel="noreferrer"> <img src={BSTLOGO} alt="bdr" /></a>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-4">
                            <a href="https://batchdialer.com" target="_blank" rel="noreferrer"><img src={BDLOGO} alt="bd" /></a>
                        </div>
                        <div className="col-md-1"></div>


                    </div>
                </div>
            </section>



        </>

    );
    return (
        <>
            <DefaultLayout View={PageMarkup} />
        </>

    )

}
export default Careers;
